var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    [
      _c(
        "CCardHeader",
        { staticClass: "vertical-center d-inline-flex flex-gap-2" },
        [
          _c(
            "h5",
            { staticClass: "mb-0" },
            [
              _vm._v(" " + _vm._s(_vm.$route.meta.label) + " "),
              _vm.form?.id && !_vm.spinners.loading
                ? [
                    _vm._v(" : "),
                    _c(
                      "h3",
                      { staticClass: "d-inline" },
                      [
                        _c("CBadge", { attrs: { color: "secondary" } }, [
                          _vm._v("#" + _vm._s(_vm.form.id)),
                        ]),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "ml-auto" },
            [
              _c(
                "CButton",
                {
                  attrs: { color: "dark", to: { name: "Merchandise Orders" } },
                },
                [
                  _c("CIcon", {
                    staticClass: "align-bottom",
                    attrs: { name: "cil-arrow-thick-left" },
                  }),
                  _vm._v(" Back "),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "CCardBody",
        [
          !_vm.isValid
            ? _c("form-summary", {
                staticClass: "form-errors alert alert-danger",
                attrs: { validator: _vm.$v.form, attributes: _vm.attributes },
              })
            : _vm._e(),
          !_vm.spinners.loading
            ? _c("CForm", { staticClass: "merchandise-order-form" }, [
                _c("div", { staticClass: "d-flex flex-column" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3 d-flex flex-column flex-md-row" },
                    [
                      _c(
                        "div",
                        { staticClass: "form-group w-100" },
                        [
                          _c("label", { staticClass: "d-block" }, [
                            _vm._v("Merchant:"),
                          ]),
                          _c("v-select", {
                            attrs: {
                              placeholder: "Select restaurant..",
                              options: _vm.allRestaurants,
                              loading:
                                _vm.spinners.loading &&
                                _vm.allRestaurants.length <= 0,
                              reduce: (option) => option.value,
                            },
                            model: {
                              value: _vm.$v.form.merchant_id.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.merchant_id, "$model", $$v)
                              },
                              expression: "$v.form.merchant_id.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mb-2 d-flex flex-column flex-md-row flex-gap-2",
                    },
                    [
                      _c("CInput", {
                        staticClass: "w-100",
                        attrs: {
                          type: "date",
                          label: "Order Date:",
                          value: _vm.$v.form.order_date.$model,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.$v.form.order_date,
                              "$model",
                              $event
                            )
                          },
                        },
                      }),
                      _c("CInput", {
                        staticClass: "w-100",
                        attrs: {
                          type: "number",
                          label: "Amount",
                          placeholder: "0.00",
                          value: _vm.$v.form.total_amount.$model,
                          step: "0.01",
                          pattern: "^\\d+(?:\\.\\d{1,2})?$",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.$v.form.total_amount,
                              "$model",
                              $event
                            )
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prepend-content",
                              fn: function () {
                                return [
                                  _c("CIcon", { attrs: { name: "cil-euro" } }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          586003441
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-0 form-group w-100" },
                    [
                      _c("CTextarea", {
                        staticClass: "mb-0",
                        attrs: {
                          label: "Notes:",
                          placeholder: "Additional notes about the order",
                          rows: "3",
                          value: _vm.$v.form.description.$model,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.$v.form.description,
                              "$model",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _c("CSpinner", { attrs: { color: "primary" } }),
        ],
        1
      ),
      _c("CCardFooter", { staticClass: "sticky-bottom" }, [
        _c(
          "div",
          {
            staticClass: "d-flex flex-wrap align-items-center",
            staticStyle: { gap: "0.75rem" },
          },
          [
            _c(
              "CButton",
              {
                attrs: {
                  color: "primary",
                  disabled:
                    _vm.spinners.loading ||
                    _vm.spinners.btnSubmit ||
                    !_vm.form.merchant_id,
                },
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [
                _vm.spinners.btnSubmit
                  ? _c("CSpinner", { attrs: { size: "sm" } })
                  : _vm._e(),
                _vm._v(" Submit "),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }